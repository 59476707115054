import './App.css';
import { useState, useEffect } from "react";
import Axios from "axios";
import 'bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import Pdf from './pdf';

function Facturacion() {
  
  const [id_usuario, setIdUsuario] = useState(0);
  const [productosFactura, setProductosFactura] = useState([]);
  const [perfiles, setPerfiles] = useState([]);
  const [perfil, setPerfil] = useState([]);
  const [nombrePerfil, setNombrePerfil] =  useState("");
  const [rfc, setRFC] = useState("");
  const [razonSocial, setRazonSocial] = useState("");
  const [regimenFiscal, setRegimenFiscal] = useState("");
  const [cp, setCP] = useState(0);
  const [total, setTotal] = useState(0);
  const [id_perfilFactura, setIdPerfilFactura] = useState(0);
  const id_venta = JSON.parse(sessionStorage.getItem("id_venta"));
  const [showPDF, setShowPDF] = useState(false);


  const navigate = useNavigate();

  const handleLogout = () => {
    sessionStorage.clear();
    navigate("/login");
  };

  useEffect(() => {
    const userDataString = sessionStorage.getItem("userData");
    const userData = userDataString ? JSON.parse(userDataString) : null;

    if (userData !== null) {
      if (userData.tipo !== '1') {
        handleLogout();
      }
      setIdUsuario(userData.id_usuario);
    } else {
      handleLogout();
    }
  }, []);

  function Facturar() {
    if(!id_venta || !id_perfilFactura){
      alert('Seleccione un perfil de facturación');
    } else {
      Axios.post('http://localhost:3001/facturar', {
        id_venta: id_venta,
        id_perfilFactura:id_perfilFactura
      }).then((result) => {
        if (result.status === 200) {
            setShowPDF(true);
        }
      });
    }
  }

  const getProductos = ()=>{
    Axios.post("http://localhost:3001/factura", {
      id_venta : id_venta
    }).then((response)=>{
      setProductosFactura(response.data);
      let subtotalTotal = 0;
      for (const producto of response.data) {
        subtotalTotal += producto.subtotal;
      }
      setTotal(subtotalTotal);
    });
  }

  const nuevoPerfil = ()=>{

    if (!nombrePerfil || !rfc || !razonSocial || !regimenFiscal ) {
      alert("Por favor, complete todos los campos antes de registrar.");
      return;
    }

    Axios.post('http://localhost:3001/perfil',{
      id_usuario:id_usuario,
      nombrePerfil:nombrePerfil,
      rfc:rfc,
      razonSocial:razonSocial,
      regimenFiscal:regimenFiscal,
      cp:cp
    }).then(()=>{
      getPerfiles();
      alert("Se registro correctamente");
      setNombrePerfil("");
      setRFC("");
      setRazonSocial("");
      setRegimenFiscal("");
      setCP(0);
    });
  }

  const getPerfiles = ()=>{
    Axios.post("http://localhost:3001/perfiles", {
      id_usuario : id_usuario
    }).then((response)=>{
      setPerfiles(response.data);
    });
  }


  const perfilSeleccionado = (id_perfil)=>{
    setIdPerfilFactura(id_perfil);
    Axios.post("http://localhost:3001/perfilselected", {
      id_perfil : id_perfil
    }).then((response)=>{
      setPerfil(response.data);
    });
  }


  const eliminarPerfil = (id_perfil) => {
    const confirmado = window.confirm('Eliminar perfil de facturación');
  
    if (confirmado) {
      Axios.put("http://localhost:3001/eliminarperfil/"+id_perfil).then(() => {
        getPerfiles();
      });
    } else {
      console.log('Eliminación cancelada');
    }
  }


  useEffect(() => {
    getProductos();
    perfilSeleccionado();
    getPerfiles();
  }, [id_usuario]);

  useEffect(() => {
    console.log(sessionStorage);
  }, );


  return (
    <div className="App">

      <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
          <div className="container-fluid">
              <form className="d-flex" role="search">
                <Link className="btn btn-outline-info" to={'/mapa'}>Sucursales</Link>
              </form>
              <form className="d-flex" role="search">
                <Link className="btn btn-outline-info" to={'/catalogo'}>Catalogo</Link>
              </form>
              <form className="d-flex" role="search">
                <Link className="btn btn-outline-info" to={'/lista'}>Lista de deseos</Link>
              </form>
              <form className="d-flex" role="search">
                <Link className="btn btn-outline-info" to={'/carrito'}>Carrito</Link>
              </form>
              <form className="d-flex" role="search">
                <Link className="btn btn-outline-info" to={'/historial'}>Historial</Link>
              </form>
              <form className="d-flex" role="search">
                <button className="btn btn-outline-danger" onClick={handleLogout}>Logout</button>
              </form>        
          </div>
        </nav>

      <div className="container-fluid mt-5">
        
        <div className="row container-fluid">
          <div className="col-md-6">
            {
                perfiles.length === 3 ? (
                  <div>
                    <h2 className=''>Perfil de Facturación</h2>
                  <form>
                      { perfil.map((val) => (
                        <div className="form-group">
                          <div className="row">
                          <div className="col-md-6">
                            <label className='' htmlFor="nombrePerfil">Nombre Perfil:</label>
                            <input type="text" className="form-control"  placeholder="Nombre" value={val.nombrePerfil} disabled />
                          </div>
                          <div className="col-md-6">
                            <label className='' htmlFor="exterior">RFC:</label>
                            <input type="text" className="form-control"  placeholder="RFC" value={val.RFC} disabled />
                          </div>
                          </div><br />
                          <div className="row">
                            <div className="col-md-6">
                              <label className='' htmlFor="colonia">Razón Social:</label>
                              <input type="text" className="form-control" placeholder="Razón Social" value={val.razonSocial} disabled />
                            </div>
                            <div className="col-md-6">
                              <label className='' htmlFor="cp">Régimen Fiscal:</label>
                              <input type="text" className="form-control" placeholder="Régimen Fiscal" value={val.regimenFiscal} disabled />
                            </div>
                          </div>
                          <div className='row'>
                            <div className="col-md-12">
                              <label className='' htmlFor="cp">CP:</label>
                              <input type="number" className="form-control" placeholder="CP" value={val.CP} disabled />
                            </div>
                          </div>
                        </div>
                      ))}
                      <br /><br />

                      <div>
                        <h3 className="">Selecciona un Perfil de Facturación:</h3>
                        <div className="btn-group-vertical" role="group">
                          {perfiles.map((val) => (
                              <button key={val.id_perfilFactura} type="button" className="btn btn-secondary mr-3" onClick={() => perfilSeleccionado(val.id_perfilFactura)}>
                                Nombre: {val.nombrePerfil} - RFC: {val.RFC}
                                <input className="btn btn-info ml-4" type="button" value="Eliminar" onClick={() => {eliminarPerfil(val.id_perfilFactura);}} />
                              </button>
                          ))}
                        </div>
                      </div>
                      
                  </form>
                  </div>
                    
                ):(
                  <div>
                    <h2 className=''>Perfil de Facturación</h2>
                  <form>
                  {
                    perfil.length > 0 ? (
                       perfil.map((val) => (
                        <div className="form-group">
                        <div className="row">
                          <div className="col-md-6">
                            <label className='' htmlFor="nombrePerfil">Nombre Perfil:</label>
                            <input type="text" className="form-control" placeholder="Nombre" value={val.nombrePerfil} disabled/>
                          </div>
                          <div className="col-md-6">
                            <label className='' htmlFor="exterior">RFC:</label>
                            <input type="text" className="form-control" placeholder="RFC" value={val.RFC} disabled/>
                          </div>
                        </div><br />
                        <div className="row">
                          <div className="col-md-6">
                            <label className='' htmlFor="colonia">Razón Social:</label>
                            <input type="text" className="form-control" placeholder="Razón Social" value={val.razonSocial} disabled/>
                          </div>
                          <div className="col-md-6">
                            <label className='' htmlFor="cp">Régimen Fiscal:</label>
                            <input type="text" className="form-control" placeholder="Régimen Fiscal" value={val.regimenFiscal} disabled />
                          </div>
                        </div><br />
                        <div className='row'>
                            <div className="col-md-12">
                              <label className='' htmlFor="cp">CP:</label>
                              <input type="number" className="form-control" placeholder="CP" value={val.CP} disabled />
                            </div>
                          </div><br />
                        <button className="btn btn-primary "onClick={() => setIdPerfilFactura(0)}>Agregar Nuevo Perfil</button>
                      </div>
                      
                      ))
                    ) : (
                        <div className="form-group">
                        <div className="row">
                          <div className="col-md-6">
                            <label className='' htmlFor="nombrePerfil">Nombre Perfil:</label>
                            <input type="text" className="form-control" placeholder="Nombre" onChange={(event) => {setNombrePerfil(event.target.value);}}/>
                          </div>
                          <div className="col-md-6">
                            <label className='' htmlFor="exterior">RFC:</label>
                            <input type="text" className="form-control" placeholder="RFC" onChange={(event) => {setRFC(event.target.value);}}/>
                          </div>
                        </div><br />
                        <div className="row">
                          <div className="col-md-6">
                            <label className='' htmlFor="colonia">Razón Social:</label>
                            <input type="text" className="form-control" placeholder="Razón Social" onChange={(event) => {setRazonSocial(event.target.value);}}/>
                          </div>
                          <div className="col-md-6">
                            <label className='' htmlFor="cp">Régimen Fiscal:</label>
                            <input type="text" className="form-control" placeholder="Régimen Fiscal" onChange={(event) => {setRegimenFiscal(event.target.value);}}/>
                          </div>
                        </div><br />
                        <div className='row'>
                            <div className="col-md-12">
                              <label className='' htmlFor="cp">CP:</label>
                              <input type="number" className="form-control" placeholder="CP" onChange={(event) => {setCP(event.target.value);}} />
                            </div>
                          </div><br />
                        <button className="btn btn-primary " onClick={nuevoPerfil}>Agregar Perfil</button>
                      </div>
                    )
                  }<br />

                      <div>
                        <h3 className="">Selecciona una Perfil de Facturación:</h3>
                        <div className="btn-group-vertical" role="group">
                          {perfiles.map((val) => (
                              <button key={val.id_perfilFactura} type="button" className="btn btn-secondary mr-3" onClick={() => perfilSeleccionado(val.id_perfilFactura)}>
                                Nombre: {val.nombrePerfil} - RFC: {val.RFC}
                                <input className="btn btn-info ml-4" type="button" value="Eliminar" onClick={() => {eliminarPerfil(val.id_perfilFactura);}} />
                              </button>
                          ))}
                        </div>
                      </div>
                      
                  </form>
                  </div>
                    
                )}
            
          </div>
        </div><br />

        <div className='container-fluid'>
        <h2 className=''>Productos</h2>
        <div className="table-responsive"> 
                <table className="table table-bordered">
                  <thead>
                    <tr>
                      <th>Producto</th>
                      <th>Sucursal</th>
                      <th>Cantidad</th>
                      <th>Precio</th>
                      <th>Subtotal</th>
                    </tr>
                  </thead>
                  {productosFactura.length > 0 ? (
                  <tbody>
                    {
                      productosFactura.map((val) => {
                        return <tr>
                          <td> {val.producto} </td>
                          <td> {val.nombre} </td>
                          <td> {val.cantidad}</td>
                          <td>$ {val.precio} </td>
                          <td>$ {val.subtotal} </td>
                        </tr>
                      })
                    }
                    <tr>
                      <td colSpan="6">Total de todos los productos: $ {total}</td>
                    </tr>
                  </tbody>
                  ) : (
                    <tbody>
                      <tr className="text-center">
                        <td colSpan='6'>No hay productos en el carrito.</td>
                      </tr>
                      <tr>
                        <td colSpan="6">Total de todos los productos: </td>
                      </tr>
                    </tbody>
                  )}
                </table>
              </div>
            </div><br />

            <div className='container-fluid'>
              <button className="btn btn-primary " onClick={()=>{Facturar()}}>Facturar</button>
            </div><br />

            {showPDF && <Pdf idVenta={id_venta} />}

        </div>

  
  <script src="https://code.jquery.com/jquery-3.5.1.slim.min.js"></script>
  <script src="https://cdn.jsdelivr.net/npm/@popperjs/core@2.5.3/dist/umd/popper.min.js"></script>
  <script src="https://stackpath.bootstrapcdn.com/bootstrap/4.5.2/js/bootstrap.min.js"></script>
          <br /><br />

    </div>
  );
}

export default Facturacion;
