import React, { useState, useEffect } from 'react';
import Axios from 'axios';
import { Document, Page, Text, View, StyleSheet, PDFViewer } from '@react-pdf/renderer';

const Pdf = ({ idVenta }) => {
  const [data, setData] = useState([]);
  const [pdfData, setPdfData] = useState(null);

  useEffect(() => {
    Axios.get(`http://localhost:3001/detallesfactura/${idVenta}`)
      .then((result) => {
        if (result.status === 200) {
          setData(result.data);
        } else {
          alert(result.data);
        }
      });
  }, [idVenta]);

  const styles = StyleSheet.create({
    page: {
      flexDirection: 'column',
      alignItems: 'center',
      margin: 10,
    },
    title: {
      fontSize: 20,
      marginBottom: 10,
    },
    subtitle: {
      fontSize: 16,
      marginBottom: 10,
    },
    table: { 
      display: "table", 
      width: "auto", 
      borderStyle: "solid", 
      borderWidth: 1, 
      borderRightWidth: 0, 
      borderBottomWidth: 0 
    }, 
    tableRow: { 
      margin: "auto", 
      flexDirection: "row" 
    },
    tableCol: { 
      width: "20%", 
      borderStyle: "solid", 
      borderWidth: 1, 
      borderLeftWidth: 0, 
      borderTopWidth: 0 
    }, 
    tableCell: { 
      margin: "auto", 
      marginTop: 5, 
      fontSize: 10 
    },
    tableFullCol:{
      width: "80%",
      borderStyle: "solid", 
      borderWidth: 1, 
      borderLeftWidth: 0, 
      borderTopWidth: 0,
      textAlign: 'left',
    }
  });

  const generatePDF = () => {
    const fechaFactura = new Date(data[0].fecha_factura);
    let año = fechaFactura.getFullYear();
    let mes = fechaFactura.getMonth() + 1; // Los meses se indexan desde 0, por lo que sumamos 1
    let dia = fechaFactura.getDate();
    const fechaFacturaFormateada = `${año}-${mes < 10 ? '0' : ''}${mes}-${dia < 10 ? '0' : ''}${dia}`;

    const fechaCompra = new Date(data[0].fecha_venta);
    año = fechaCompra.getFullYear();
    mes = fechaCompra.getMonth() + 1; // Los meses se indexan desde 0, por lo que sumamos 1
    dia = fechaCompra.getDate();
    const fechaCompraFormateada = `${año}-${mes < 10 ? '0' : ''}${mes}-${dia < 10 ? '0' : ''}${dia}`;


    const pdfData = (
      <Document>
        <Page size="A4" style={styles.page}>
          <Text style={styles.title}>Factura</Text>
          <Text style={styles.subtitle}>Fecha de facturación: {fechaFacturaFormateada}</Text>
          <Text style={styles.subtitle}>Fecha de compra: {fechaCompraFormateada}</Text>
          <Text style={styles.subtitle}>RFC: {data[0].rfc} Razon Social: {data[0].razonSocial} </Text>
          <Text style={styles.subtitle}>Regimen Fiscal: {data[0].regimenFiscal} </Text>
          <Text style={styles.subtitle}>Código Postal: {data[0].cp} </Text>
          <View style={styles.table}>

            <View style={styles.tableRow}>
              <View style={styles.tableCol}>
                <View style={styles.tableCell}>
                  <Text>Nombre del Producto</Text>
                </View>
              </View>
              <View style={styles.tableCol}>
                <View style={styles.tableCell}>
                  <Text>Sucursal</Text>
                </View>
              </View>
              <View style={styles.tableCol}>
                <View style={styles.tableCell}>
                  <Text>Precio Unitario</Text>
                </View>
              </View>
              <View style={styles.tableCol}>
                <View style={styles.tableCell}>
                  <Text>Cantidad</Text>
                </View>
              </View>
              <View style={styles.tableCol}>
                <View style={styles.tableCell}>
                  <Text>Subtotal</Text>
                </View>
              </View>
            </View>
          </View>

          {data.map(val => (
            <View style={styles.table} key={val.id}>
              <View style={styles.tableRow}>
                <View style={styles.tableCol}>
                  <View style={styles.tableCell}>
                    <Text>{val.producto}</Text>
                  </View>
                </View>
                <View style={styles.tableCol}>
                  <View style={styles.tableCell}>
                    <Text>{val.sucursal}</Text>
                  </View>
                </View>
                <View style={styles.tableCol}>
                  <View style={styles.tableCell}>
                    <Text>{`$${val.precio}`}</Text>
                  </View>
                </View>
                <View style={styles.tableCol}>
                  <View style={styles.tableCell}>
                    <Text>{val.cantidad}</Text>
                  </View>
                </View>
                <View style={styles.tableCol}>
                  <View style={styles.tableCell}>
                    <Text>{`$${val.cantidad * val.precio}`}</Text>
                  </View>
                </View>
              </View>
            </View>
          ))}
          <View style={styles.table}>
            <View style={styles.tableRow}>
              <View style={styles.tableFullCol}>
                <View style={styles.tableCell}>
                  <Text>Total sin iva: </Text>
                </View>
              </View>
              <View style={styles.tableCol}>
                <View style={styles.tableCell}>
                  <Text>${(data[0].total / 1.16).toFixed(2)}</Text>
                </View>
              </View>
            </View>
          </View>
          <View style={styles.table}>
            <View style={styles.tableRow}>
              <View style={styles.tableFullCol}>
                <View style={styles.tableCell}>
                  <Text>Iva: </Text>
                </View>
              </View>
              <View style={styles.tableCol}>
                <View style={styles.tableCell}>
                  <Text>${((data[0].total/1.16)*.16).toFixed(2)}</Text>
                </View>
              </View>
            </View>
          </View>
          <View style={styles.table}>
            <View style={styles.tableRow}>
              <View style={styles.tableFullCol}>
                <View style={styles.tableCell}>
                  <Text>Total con iva: </Text>
                </View>
              </View>
              <View style={styles.tableCol}>
                <View style={styles.tableCell}>
                  <Text>${data[0].total}</Text>
                </View>
              </View>
            </View>
          </View>
        </Page>
      </Document>
    );

    setPdfData(pdfData);
  };

  return (
    <div>
      <button className="btn btn-primary text-white" onClick={generatePDF}>Generar Factura</button>
      {pdfData && (
        <PDFViewer width="1000" height="600">
          {pdfData}
        </PDFViewer>
      )}
    </div>
  );
};

export default Pdf;
