import './App.css';
import { useState, useEffect } from "react";
import Axios from "axios";
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link, useNavigate } from 'react-router-dom';
import { PayPalScriptProvider, PayPalButtons } from '@paypal/react-paypal-js';


function Compra() {
  
  const [id_usuario, setIdUsuario] = useState(0);
  const [productos, setProductosCarrito] = useState([]);
  const [direcciones, setDirecciones] = useState([]);
  const [direccion, setDireccion] = useState([]);
  const [total, setTotal] = useState(0);
  const [colonia, setColonia] = useState("");
  const [calle, setCalle] = useState("");
  const [numero_exterior, setNumero] = useState(0);
  const [cp, setCP] = useState(0);
  const [id_direccion, setIdDicreccion] = useState(0);


  const navigate = useNavigate();

  const handleLogout = () => {
    sessionStorage.clear();
    navigate("/login");
  };

  useEffect(() => {
    const userDataString = sessionStorage.getItem("userData");
    const userData = userDataString ? JSON.parse(userDataString) : null;

    if (userData !== null) {
      if (userData.tipo !== '1') {
        handleLogout();
      }
      setIdUsuario(userData.id_usuario);
    } else {
      handleLogout();
    }
  }, []);

  const getProductos = ()=>{
    Axios.post("http://localhost:3001/carrito", {
      id_usuario : id_usuario
    }).then((response)=>{
      setProductosCarrito(response.data);
      let subtotalTotal = 0;
      for (const producto of response.data) {
        subtotalTotal += producto.subtotal;
      }
      setTotal(subtotalTotal);
    });
  }

  const nuevaDireccion = ()=>{

    if (!colonia || !numero_exterior || !calle || !cp ) {
      alert("Por favor, complete todos los campos antes de registrar.");
      return;
    }

    Axios.post('http://localhost:3001/direccion',{
      id_usuario:id_usuario,
      colonia:colonia,
      numero_exterior:numero_exterior,
      calle:calle,
      cp:cp
    }).then(()=>{
      getDirecciones();
      alert("Se registro correctamente");
      setCalle("");
      setColonia("");
      setNumero(0);
      setCP(0);
    });
  }

  const getDirecciones = ()=>{
    Axios.post("http://localhost:3001/direcciones", {
      id_usuario : id_usuario
    }).then((response)=>{
      setDirecciones(response.data);
    });
  }


  const direccionSeleccionada = (id_direccion)=>{
    setIdDicreccion(id_direccion);
    Axios.post("http://localhost:3001/direccionselected", {
      id_direccion : id_direccion
    }).then((response)=>{
      setDireccion(response.data);
    });
  }


  const eliminarDireccion = (id_direccion) => {
    const confirmado = window.confirm('Eliminar dirección');
  
    if (confirmado) {
      Axios.put("http://localhost:3001/eliminardir/"+id_direccion).then(() => {
        getDirecciones();
      });
    } else {
      console.log('Eliminación cancelada');
    }
  }

  const comprar = ()=>{

    if(id_direccion > 0){
      Axios.post('http://localhost:3001/compra',{
        id_usuario:id_usuario,
        id_direccion:id_direccion,
        subtotal:total,
        productos: JSON.stringify(productos)
      }).then(()=>{
        alert("Compra realizada");
        navigate('/catalogo');
      });
    } else {
      alert('Selecciona una dirección');
    }
  }

  useEffect(() => {
    getProductos();
    direccionSeleccionada();
    getDirecciones();
  }, [id_usuario]);


    


  return (
    <div className="App">

      <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
          <div className="container-fluid">
              <form className="d-flex" role="search">
                <Link className="btn btn-outline-info" to={'/mapa'}>Sucursales</Link>
              </form>
              <form className="d-flex" role="search">
                <Link className="btn btn-outline-info" to={'/catalogo'}>Catalogo</Link>
              </form>
              <form className="d-flex" role="search">
                <Link className="btn btn-outline-info" to={'/lista'}>Lista de deseos</Link>
              </form>
              <form className="d-flex" role="search">
                <Link className="btn btn-outline-info" to={'/carrito'}>Carrito</Link>
              </form>
              <form className="d-flex" role="search">
                <Link className="btn btn-outline-info" to={'/historial'}>Historial</Link>
              </form>
              <form className="d-flex" role="search">
                <button className="btn btn-outline-danger" onClick={handleLogout}>Logout</button>
              </form>        
          </div>
        </nav>

      <div className="container-fluid mt-5">
        
        <div className="row container-fluid">
          <div className="col-md-6">
            {
                direcciones.length === 3 ? (
                  <div>
                    <h2 className='text-black'>Dirección de Envío</h2>
                  <form>
                      { direccion.map((val) => (
                        <div className="form-group">
                          <div className="row">
                          <div className="col-md-6">
                            <label className='text-black' htmlFor="calle">Calle:</label>
                            <input type="text" className="form-control"  id="calle" placeholder="Calle" value={val.calle} disabled />
                          </div>
                          <div className="col-md-6">
                            <label className='text-black' htmlFor="exterior">No. Exterior:</label>
                            <input type="number" className="form-control" id="exterior" placeholder="No. Exterior" value={val.numero_exterior} disabled />
                          </div>
                          </div><br />
                          <div className="row">
                            <div className="col-md-6">
                              <label className='text-black' htmlFor="colonia">Colonia:</label>
                              <input type="text" className="form-control" id="colonia" placeholder="Colonia" value={val.colonia} disabled />
                            </div>
                            <div className="col-md-6">
                              <label className='text-black' htmlFor="cp">CP:</label>
                              <input type="number" className="form-control" id="cp" placeholder="CP" value={val.cp} disabled />
                            </div>
                          </div>
                        </div>
                      ))}
                      <br /><br />

                      <div>
                        <h3 className="text-black">Selecciona una Dirección de Envío:</h3>
                        <div className="btn-group-vertical" role="group">
                          {direcciones.map((dir) => (
                              <button key={dir.id_direccion} type="button" className="btn btn-secondary mr-3" onClick={() => direccionSeleccionada(dir.id_direccion)}>
                                {dir.colonia}, {dir.calle}, {dir.numero_exterior}, CP: {dir.cp}
                                <input className="btn btn-info ml-4" type="button" value="Eliminar" onClick={() => {eliminarDireccion(dir.id_direccion);}} />
                              </button>
                          ))}
                        </div>
                      </div>
                      
                  </form>
                  </div>
                    
                ):(
                  <div>
                    <h2 className='text-black'>Dirección de Envío</h2>
                  <form>
                  {
                    direccion.length > 0 ? (
                       direccion.map((val) => (
                        <div className="form-group">
                        <div className="row">
                          <div className="col-md-6">
                            <label className='text-black' htmlFor="calle">Calle:</label>
                            <input type="text" className="form-control"  id="calle" placeholder="Calle" value={val.calle} disabled/>
                          </div>
                          <div className="col-md-6">
                            <label className='text-black' htmlFor="exterior">No. Exterior:</label>
                            <input type="number" className="form-control" id="exterior" placeholder="No. Exterior" value={val.numero_exterior} disabled/>
                          </div>
                        </div><br />
                        <div className="row">
                          <div className="col-md-6">
                            <label className='text-black' htmlFor="colonia">Colonia:</label>
                            <input type="text" className="form-control" id="colonia" placeholder="Colonia" value={val.colonia} disabled/>
                          </div>
                          <div className="col-md-6">
                            <label className='text-black' htmlFor="cp">CP:</label>
                            <input type="number" className="form-control" id="cp" placeholder="CP" value={val.cp} disabled />
                          </div>
                        </div><br />
                        <button className="btn btn-info text-black"onClick={() => setIdDicreccion(0)}>Agregar Nueva Dirección</button>
                      </div>
                      
                      ))
                    ) : (
                        <div className="form-group">
                        <div className="row">
                          <div className="col-md-6">
                            <label className='text-black' htmlFor="calle">Calle:</label>
                            <input type="text" className="form-control"  id="calle" placeholder="Calle" onChange={(event) => {setCalle(event.target.value);}}/>
                          </div>
                          <div className="col-md-6">
                            <label className='text-black' htmlFor="exterior">No. Exterior:</label>
                            <input type="number" className="form-control" id="exterior" placeholder="No. Exterior" onChange={(event) => {setNumero(event.target.value);}}/>
                          </div>
                        </div><br />
                        <div className="row">
                          <div className="col-md-6">
                            <label className='text-black' htmlFor="colonia">Colonia:</label>
                            <input type="text" className="form-control" id="colonia" placeholder="Colonia" onChange={(event) => {setColonia(event.target.value);}}/>
                          </div>
                          <div className="col-md-6">
                            <label className='text-black' htmlFor="cp">CP:</label>
                            <input type="number" className="form-control" id="cp" placeholder="CP" onChange={(event) => {setCP(event.target.value);}}/>
                          </div>
                        </div><br />
                        <button className="btn btn-info text-black" onClick={nuevaDireccion}>Agregar Dirección</button>
                      </div>
                    )
                  }<br />

                      <div>
                        <h3 className="text-black">Selecciona una Dirección de Envío:</h3>
                        <div className="btn-group-vertical" role="group">
                          {direcciones.map((dir) => (
                              <button key={dir.id_direccion} type="button" className="btn btn-secondary mr-3" onClick={() => direccionSeleccionada(dir.id_direccion)}>
                                {dir.colonia}, {dir.calle}, {dir.numero_exterior}, CP: {dir.cp}
                                <input className="btn btn-info ml-4" type="button" value="Eliminar" onClick={() => {eliminarDireccion(dir.id_direccion);}} />
                              </button>
                          ))}
                        </div>
                      </div>
                      
                  </form>
                  </div>
                    
                )}
            
          </div>
        </div><br />

        <div className='container-fluid'>
        <h2 className='text-black'>Productos</h2>
        <div className="table-responsive"> 
                <table className="table table-bordered table-dark">
                  <thead>
                    <tr>
                      <th>Producto</th>
                      <th>Sucursal</th>
                      <th>Cantidad</th>
                      <th>Precio</th>
                      <th>Subtotal</th>
                    </tr>
                  </thead>
                  {productos.length > 0 ? (
                  <tbody>
                    {
                      productos.map((val) => {
                        return <tr>
                          <td> {val.producto} </td>
                          <td> {val.nombre} </td>
                          <td> {val.cantidad}</td>
                          <td>$ {val.precio} </td>
                          <td>$ {val.subtotal} </td>
                        </tr>
                      })
                    }
                    <tr>
                      <td colSpan="6">Total de todos los productos: $ {total}</td>
                    </tr>
                  </tbody>
                  ) : (
                    <tbody>
                      <tr className="text-center">
                        <td colSpan='6'>No hay productos en el carrito.</td>
                      </tr>
                      <tr>
                        <td colSpan="6">Total de todos los productos: </td>
                      </tr>
                    </tbody>
                  )}
                </table>
              </div>
            </div><br />

            <div className='container-fluid'>
              <button className="btn btn-info text-black" onClick={() => comprar()}>Comprar</button>
            </div>


          </div>

  
  <script src="https://code.jquery.com/jquery-3.5.1.slim.min.js"></script>
  <script src="https://cdn.jsdelivr.net/npm/@popperjs/core@2.5.3/dist/umd/popper.min.js"></script>
  <script src="https://stackpath.bootstrapcdn.com/bootstrap/4.5.2/js/bootstrap.min.js"></script>
          <br /><br />

    </div>
  );
}

export default Compra;
