import './App.css';
import { useState, useEffect } from "react";
import Axios from "axios";
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link, useNavigate  } from 'react-router-dom';
import md5 from "crypto-js/md5";


function Login() {

  const [sessionData, setSessionData] = useState({});
  const [correo, setCorreo] = useState("");
  const [psw, setPsw] = useState("");

  function handleLogin() {
    Axios.post('http://localhost:3001/login', {
      correo : correo,
      psw : md5(psw).toString()
    })
      .then((response) => {
        if(response.data.success){
          //AQUI GUARDO LOS DATOS EN LA SESION
          sessionStorage.setItem("userData", JSON.stringify(response.data.user));
          console.log(response.data['user']['tipo']);
          if (response.data['user']['tipo']==1 ) {
            window.location.href = "/catalogo";
          } else if(response.data['user']['tipo']==2) {
            window.location.href = "/admin";
          }
        }else{
          alert("Crendenciales incorrectas");
        }
      });
  }

  
  return (

    <div className='App'>

      <div className="container-fluid">

      <div className="row justify-content-center">

          <div className="col-xl-10 col-lg-12 col-md-9">

              <div className="card o-hidden border-0 shadow-lg my-5">
                  <div className="card-body p-0">
                      <div className="row">
                          <div className="col-lg-6 d-none d-lg-block bg-login-image img-fluid"></div>
                          <div className="col-lg-6">
                              <div className="p-5">
                                  <div className="text-center"><br /><br />
                                      <h1 className="h2 text-gray-900 mb-2">Bienvenidos!</h1><br /><br />
                                  </div> 
                                  <form className="user">
                                      <div className="form-group">
                                          <input type="email" className="form-control form-control-user border-2 border-black" aria-describedby="emailHelp"
                                              placeholder="Correo" onChange={(event) => {setCorreo(event.target.value);}}/>
                                      </div>
                                      <div className="form-group">
                                          <input type="password" className="form-control form-control-user border-2 border-black" placeholder="Contraseña"
                                          onChange={(event) => {setPsw(event.target.value);}}/>
                                      </div><br />
                                      <button className="btn btn-info btn-user btn-block border-1 border-black" onClick={()=> handleLogin()} >
                                        Iniciar Sesión
                                      </button>
                                  </form>
                                  <form className='user py-2'>
                                    <Link className="btn btn-info btn-user btn-block border-1 border-black" to="/register">
                                      Registrarse
                                    </Link>
                                    <Link className="btn btn-info btn-user btn-block border-1 border-black" to="/">
                                      Cancelar
                                    </Link>
                                  </form>
                                  <hr/><br /><br />
                              </div>
                          </div>
                      </div>
                  </div>
              </div>

          </div>

      </div>

</div>
    

    
</div>
  );
}

export default Login;
