import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './bootstrap.min.css';
import './sb-admin-2.css';
import 'bootstrap';
import { useState, useEffect } from "react";
import Axios from "axios";
import { Link, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart } from '@fortawesome/free-solid-svg-icons';
    
function Catalogo() {

  const [id_usuario, setIdUsuario] = useState(0);
  const [productosLista, setProductosLista] = useState([]);
  const [sucursalesLista, setSucursalesLista] = useState([]);
  const [id_sucursal, setIdSucursal] = useState(0);
  const [idInventario, setIdInventario] = useState(0);
  const [cantidad, setCantidad] = useState(1);
  const [clickedHeartIndex, setClickedHeartIndex] = useState(null);

  const navigate = useNavigate();

  const handleLogout = () => {
    sessionStorage.clear();
    navigate("/login");
  };
  
  useEffect(() => {
    const userDataString = sessionStorage.getItem("userData");
    const userData = userDataString ? JSON.parse(userDataString) : null;

    if (userData !== null) {
      if (userData.tipo !== '1') {
        handleLogout();
      }
      setIdUsuario(userData.id_usuario);
    } else {
      handleLogout();
    }
  }, []);


  const getProductos = ()=>{
    Axios.post("http://localhost:3001/catalogo", {
      id_sucursal : id_sucursal
    }).then((response)=>{
      setProductosLista(response.data);
      console.log("Productos obtenidos:", response.data);
    });
  }

  const getSucursales = ()=>{
    Axios.get("http://localhost:3001/sucursales")
    .then((response)=>{
      setSucursalesLista(response.data);
    });
  }

  const agregar = (id_inventario, precio, index) => {
    Axios.post('http://localhost:3001/agregarlista', {
      id_usuario: id_usuario,
      id_inventario: id_inventario,
      cantidad: cantidad,
      subtotal: cantidad * precio
    })
      .then(() => {
        setClickedHeartIndex(index);
        alert("Producto agregado");
      })
      .catch((error) => {
        if (error.response && error.response.status === 409) {
          alert("El producto ya está en la lista");
        } else {
          console.error("Error al agregar el producto:", error);
        }
      });
  };
  

  const resetClickedHeart = () => {
    setClickedHeartIndex(null);
  };

  useEffect(() => {
    if (idInventario !== null) {
      sessionStorage.setItem("id_inventario", JSON.stringify(idInventario));
      resetClickedHeart();
    }
  }, [idInventario]);


  useEffect(() => {
    getSucursales();
  }, []);
  
  useEffect(() => {
    getProductos(); 
  }, [id_sucursal]);

  useEffect(() => {
    if (idInventario !== null) {
      sessionStorage.setItem("id_inventario", JSON.stringify(idInventario));
    }
  }, [idInventario]);

    return (
            
    <div className='App'>

      <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
          <div className="container-fluid">
              <form className="d-flex" role="search">
                <Link className="btn btn-outline-info" to={'/mapa'}>Sucursales</Link>
              </form>
              <form className="d-flex" role="search">
                <Link className="btn btn-outline-info" to={'/catalogo'}>Catalogo</Link>
              </form>
              <form className="d-flex" role="search">
                <Link className="btn btn-outline-info" to={'/lista'}>Lista de deseos</Link>
              </form>
              <form className="d-flex" role="search">
                <Link className="btn btn-outline-info" to={'/carrito'}>Carrito</Link>
              </form>
              <form className="d-flex" role="search">
                <Link className="btn btn-outline-info" to={'/historial'}>Historial</Link>
              </form>
              <form className="d-flex" role="search">
                <button className="btn btn-outline-danger" onClick={handleLogout}>Logout</button>
              </form>        
          </div>
        </nav>
    
        <div className="container-fluid">
    
          <div className="p-5">
            <div className="text-center">
                <h1 className="h1 mb-4">Catalogo</h1>
            </div>
          </div>

            <div className=' container-fluid text-center'>
              <h3 className='text-info'>Seleccione una sucursal</h3>
            </div>
            <br />
            
          <section id="categoryWomen">
        <div className="container-fluid ">
          <div className="row h-100">
            <div className="col-12">
              <nav>
                <div className="nav nav-tabs majestic-tabs mb-4 justify-content-center border-black border-3" id="nav-tab" role="tablist">
                  {sucursalesLista.map((val) => (
                      <button key={val.id_sucursal} onClick={() => setIdSucursal(val.id_sucursal)} className="nav-link border-2 border-black border-opacity-50 text-black" id={`nav-${val.id_sucursal}-tab`} data-bs-toggle="tab" data-bs-target={`#nav-${val.id_sucursal}`} type="button" role="tab" aria-controls={`nav-${val.id_sucursal}`} aria-selected="true">{val.nombre}</button>
                      ))}
                </div>
                    
                    <div className="tab-content" id="nav-tabContent">

                    {sucursalesLista.map((val) => (

                      <div className="tab-pane fade" key={val.id_sucursal} id={`nav-${val.id_sucursal}`} role="tabpanel" aria-labelledby={`nav-${val.id_sucursal}-tab`}>
                        <div className="tab-content" id={`pills-tabContent${val.id_sucursal}`}>
                                <div className="carousel-inner">
                                  <div className="carousel-item active" data-bs-interval="10000">
                                  {productosLista.length > 0 ? (
                                    <div className="row h-100 align-items-center g-2">
                                      {productosLista.map((producto, index) => (
                                        <div className="col-sm-6 col-md-3 mb-3 mb-md-0 h-100" key={producto.id_producto}>
                                          <a className="" href={'/item'} onClick={() => {setIdInventario(producto.id_inventario);}} >
                                          <div className="card card-span h-100 text-black">
                                            <img className="" height={200} src={`http://localhost:3001/${producto.ruta}`} alt="..." />
                                            <div className="card-img-overlay ps-0">
                                            </div>
                                            <div className="card-body ps-0 bg-200 ml-4">
                                              <h5 className="fw-bold text-1000 text-truncate"> {producto.nombre} </h5>
                                              <div className="fw-bold">
                                                <span className="text-primary">$ {producto.precio} </span>
                                              </div>
                                            </div>
                                          </div>
                                          </a>
                                            <button onClick={() => {agregar(producto.id_inventario, producto.precio, index)}} className="btn btn-link position-absolute top-0 end-0">
                                              <FontAwesomeIcon icon={faHeart} color={clickedHeartIndex === index ? 'red' : 'black'} size="2x" />
                                            </button>
                                        </div>
                                      ))}
                                    </div>
                                    ) : (
                                    <div className="text-center">
                                      <br />
                                      <br />
                                      <h3 className='text-black'>No hay productos disponibles</h3>
                                    </div>
                                  )}
                                  </div>
                                </div>
                        </div>
                      </div>
                      ))}

                    </div>
                
              </nav>
            </div>
          </div>
        </div>
      </section>
          
    
        </div>

      <footer>
        <div className="container py-5">

        </div>
      </footer>

    </div>
        
        
        );
}

export default Catalogo;