import './App.css';
import { useState, useEffect } from "react";
import Axios from "axios";
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link, useNavigate } from 'react-router-dom';


function CrudProductos() {
  
  const [nombre, setNombre] = useState("");
  const [descripcion, setDescripcion] = useState("");
  const [categoria, setCategoria] = useState("");
  const [marca, setMarca] = useState("");
  const [precio, setPrecio] = useState(0.0);
  const [editar, setEditar] = useState(false);
  const [id_producto, setIdProducto] = useState(0);
  const [imagen, setImagen] = useState(null);

  const [productosLista, setProductosLista] = useState([]);

  const navigate = useNavigate();

  const handleLogout = () => {
    sessionStorage.clear();
    navigate("/login");
  };

  const userData = JSON.parse(sessionStorage.getItem("userData"));

  if (userData!=null) {
    if (userData.tipo != '2') {
      handleLogout();
    }
  } else {
    handleLogout();
  }

  const registrar = () => {
    if (!nombre || !descripcion || !categoria || !marca || !precio || !imagen) {
      alert("Por favor, complete todos los campos antes de registrar.");
      return;
    }
  
    const formData = new FormData();
  
    formData.append("nombre", nombre);
    formData.append("descripcion", descripcion);
    formData.append("categoria", categoria);
    formData.append("marca", marca);
    formData.append("precio", precio);
    formData.append("imagen", imagen);
  
    Axios.post("http://localhost:3001/createpr", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      }
    }).then(() => {
        getProductos();
        alert("Se registró correctamente");
        cancelar();
      });
  };
  

  const getProductos = ()=>{
    Axios.get("http://localhost:3001/productos")
    .then((response)=>{
      setProductosLista(response.data);
    });
  }

  useEffect(() => {
    getProductos();
  }, []);

  const eliminarProducto = (id_producto) => {
    Axios.put("http://localhost:3001/eliminarpr/"+id_producto).then(() => {
      alert("El producto ha sido eliminado correctamente");
      getProductos();
    });
  }

  const infoProducto = (val)=>{
    setEditar(true);
    setNombre(val.nombre);
    setDescripcion(val.descripcion);
    setCategoria(val.categoria);
    setMarca(val.marca);
    setPrecio(val.precio);
    setIdProducto(val.id_producto);
    setImagen(val.imagen);
  }

  const cancelar = ()=>{
    setEditar(false);
    setNombre("");
    setDescripcion("");
    setCategoria("");
    setMarca("");
    setPrecio(0.0);
    setImagen(null);
  }

    const modificarProducto = ()=>{

      if (!nombre || !descripcion || !categoria || !marca || !precio || !imagen) {
        alert("Por favor, complete todos los campos antes de modificar.");
        return;
      }

      Axios.put('http://localhost:3001/updatepr',{
        nombre:nombre,
        descripcion:descripcion,
        categoria:categoria,
        marca:marca,
        precio:precio,
        id_producto:id_producto, 
        imagen: imagen
      }).then(()=>{
        cancelar();
        getProductos();
        alert("El producto se ha modificado correctamente");
      });
    }

  return (
    <div className="App">

      <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
        <div className="container">
          <form className="d-flex" role="search">
            <Link className="btn btn-info" to={'/admin'}>Regresar</Link>
          </form>
          <a className="navbar-brand mx-auto text-white">CRUD PRODUCTOS</a>
          <form className="d-flex" role="search">
            <button className="btn btn-danger" onClick={handleLogout}>Logout</button>
          </form>
        </div>
      </nav>

        <br /><br />

        <div className="container py-4">
          <div className="row justify-content-center">
            <div className="col-md-8">
            <div className="border border-dark border-1 p-5 d-flex flex-column align-items-center" style={{ backgroundColor: "#888888" }}>
                <label className="text-white">Nombre:</label>
                <input
                  value={nombre} placeholder='Nombre'
                  onChange={(event) => {
                    setNombre(event.target.value);
                  }}
                  type="text"
                  className="form-control"
                />
                <label className="text-white ">Descripcion:</label>
                <input
                  value={descripcion} placeholder='Descripción'
                  onChange={(event) => {
                    setDescripcion(event.target.value);
                  }}
                  type="text"
                  className="form-control"
                />
                <label className="text-white ">Categoría:</label>
                <input
                  value={categoria} placeholder='Categoría'
                  onChange={(event) => {
                    setCategoria(event.target.value);
                  }}
                  type="text"
                  className="form-control"
                />
                <label className="text-white ">Marca:</label>
                <input
                  value={marca} placeholder='Marca'
                  onChange={(event) => {
                    setMarca(event.target.value);
                  }}
                  type="text"
                  className="form-control"
                />
                <label className="text-white">Precio:</label>
                <input
                  value={precio} placeholder='Precio'
                  onChange={(event) => {
                    setPrecio(event.target.value);
                  }}
                  type='number'
                  className="form-control"
                />
                <label className="label-blancos">Imagen:</label>
                <input
                  onChange={(event) => {
                    setImagen(event.target.files[0]);
                  }}
                  type="file"
                  className="form-control"
                />
                <br />

                {editar ? (
                  <div>
                    <button type="button" className="btn btn-info" onClick={modificarProducto}>
                      Actualizar
                    </button>
                    <button type="button" className="btn btn-danger" onClick={cancelar}>
                      Cancelar
                    </button>
                  </div>
                ) : (
                  <button type="button" className="btn btn-info" onClick={registrar}>
                    Registrar
                  </button>
                )}
              </div>
            </div>
          </div>
        </div><br />




      <table className="table">
        <thead className='table-dark'>
          <tr className="table-active">
            <th scope="col">ID</th>
            <th scope="col">Imagen</th>
            <th scope="col">Nombre</th>
            <th scope="col">Descripción</th>
            <th scope="col">Categoría</th>
            <th scope="col">Marca</th>
            <th scope="col">Precio</th>
            <th scope="col">Estatus</th>
            <th scope="col">Modificar</th>
            <th scope="col">Eliminar</th>
          </tr>
        </thead>
        <tbody>
          {
             productosLista.map((val,key)=>{
              return <tr className='border border-light border-1' key={val.id_producto}>
                  <th className="table-active" scope="row">{val.id_producto}</th>
                  <td className="table-active"><img width={100} height={100} src={`http://localhost:3001/${val.ruta}`} alt={val.nombre} /></td>
                  <td className="table-active">{val.nombre}</td>
                  <td className="table-active">{val.descripcion}</td>
                  <td className="table-active">{val.categoria}</td>
                  <td className="table-active">{val.marca}</td>
                  <td className="table-active">{val.precio}</td>
                  <td className="table-active">{val.estatus}</td>
                  <td className="table-active"><button type="button" className="btn btn-info" onClick={() =>{
                    infoProducto(val);
                  }}>Modificar</button></td>
                  <td colSpan="2" className="table-active"><button type="button" className="btn btn-danger" onClick={() => eliminarProducto(val.id_producto)}>Eliminar</button></td>
                </tr>
             })
          }
              
        </tbody>
      </table>

          {
             productosLista.map((val)=>{
                <img src={val.ruta} className="d-block w-50 mx-auto h-100 img-fluid" alt="Slide 2" />
             })
          }

    </div>
  );
}

export default CrudProductos;
