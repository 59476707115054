import './App.css';
import { useState, useEffect } from "react";
import Axios from "axios";
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link, useNavigate } from 'react-router-dom';


function CrudSucursales() {

  const [nombre, setNombre] = useState("");
  const [calle, setCalle] = useState("");
  const [colonia, setColonia] = useState("");
  const [numero_exterior, setNumeroExterior] = useState(0);
  const [telefono, setTelefono] = useState("");
  const [correo, setCorreo] = useState("");
  const [editar, setEditar] = useState(false);
  const [id_sucursal, setIdSucursal] = useState(0);
  const [lat, setLat] = useState(0);
  const [lng, setLng] = useState(0);

  const [sucursalLista, setSucursalLista] = useState([]);

  const navigate = useNavigate();

  const handleLogout = () => {
    sessionStorage.clear();
    navigate("/login");
  };

  const userData = JSON.parse(sessionStorage.getItem("userData"));

  if (userData!=null) {
    if (userData.tipo != '2') {
      handleLogout();
    }
  } else {
    handleLogout();
  }

  const registrar = ()=>{

    if (!nombre || !calle || !colonia || !numero_exterior || !telefono ||!correo || !lat || !lng) {
      alert("Por favor, complete todos los campos antes de añadir una nueva sucursal.");
      return;
    }

    Axios.post('http://localhost:3001/createsucur',{
      nombre:nombre,
      calle:calle,
      colonia:colonia,
      numero_exterior:numero_exterior,
      telefono:telefono,
      correo:correo,
      lat:lat,
      lng:lng
    }).then(()=>{
      getSucursales();
      alert("Se añadió correctamente");
      cancelar();
    });
  }

  const getSucursales = ()=>{
    Axios.get("http://localhost:3001/sucursales")
    .then((response)=>{
      setSucursalLista(response.data);
    });
  }

  useEffect(() => {
    getSucursales();
  }, []);

  const eliminarSucursal = (id_sucursal) => {
    Axios.put("http://localhost:3001/eliminarsucur/"+id_sucursal).then(() => {
      alert("La sucursal ha sido eliminada correctamente");
      getSucursales();
    });
  }

  const infoSucursal = (val)=>{
    setEditar(true);
    setNombre(val.nombre);
    setCalle(val.calle);
    setColonia(val.colonia);
    setNumeroExterior(val.numero_exterior);
    setTelefono(val.telefono);
    setCorreo(val.correo);
    setIdSucursal(val.id_sucursal);
    setLat(val.lat);
    setLng(val.lng);
  }

  const cancelar = ()=>{
    setEditar(false);
    setNombre("");
    setCalle("");
    setColonia("");
    setNumeroExterior(0);
    setTelefono("");
    setCorreo("");
    setLat(0);
    setLng(0);
  }

    const modificarSucursal = ()=>{

      if (!nombre || !calle || !colonia || !numero_exterior || !telefono ||!correo || !lat || !lng) {
        alert("Por favor, complete todos los campos antes de modificar.");
        return;
      }

      Axios.put('http://localhost:3001/updatesucur',{
        nombre:nombre,
        calle:calle,
        colonia:colonia,
        numero_exterior:numero_exterior,
        telefono:telefono,
        correo:correo,
        lat:lat,
        lng:lng,
        id_sucursal:id_sucursal
      }).then(()=>{
        cancelar();
        alert("La sucursal se ha modificado correctamente");
        getSucursales();
      });
    }

  return (
    <div className="App">

      <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
        <div className="container">
          <form className="d-flex" role="search">
            <Link className="btn btn-info" to={'/admin'}>Regresar</Link>
          </form>
          <a className="navbar-brand mx-auto text-white">CRUD SUCURSALES</a>
          <form className="d-flex" role="search">
            <button className="btn btn-danger" onClick={handleLogout}>Logout</button>
          </form>
        </div>
      </nav>

        <br /><br />

        <div className="container py-4">
          <div className="row justify-content-center">
            <div className="col-md-8">
            <div className="border border-dark border-1 p-5 d-flex flex-column align-items-center" style={{ backgroundColor: "#888888" }}>
                <label className="text-white">Nombre:</label>
                <input
                  value={nombre} placeholder='Nombre'
                  onChange={(event) => {
                    setNombre(event.target.value);
                  }}
                  type="text"
                  className="form-control"
                />
                <label className="text-white">Calle:</label>
                <input
                  value={calle} placeholder='Calle'
                  onChange={(event) => {
                    setCalle(event.target.value);
                  }}
                  type="text"
                  className="form-control"
                />
                <label className="text-white">Colonia:</label>
                <input
                  value={colonia} placeholder='Colonia'
                  onChange={(event) => {
                    setColonia(event.target.value);
                  }}
                  type="text"
                  className="form-control"
                />
                <label className="text-white">Número Exterior:</label>
                <input
                  value={numero_exterior} placeholder='Numero Exterior'
                  onChange={(event) => {
                    setNumeroExterior(event.target.value);
                  }}
                  type="number"
                  className="form-control"
                />
                <label className="text-white">Telefono:</label>
                <input
                  value={telefono} maxLength={10} placeholder='Telefono'
                  onChange={(event) => {
                    setTelefono(event.target.value);
                  }}
                  type='text'
                  className="form-control"
                />
                <label className="text-white">Correo:</label>
                <input
                  value={correo} placeholder='Correo'
                  onChange={(event) => {
                    setCorreo(event.target.value);
                  }}
                  type='email'
                  className="form-control"
                />
                <label className="text-white">Latitud:</label>
                <input
                  value={lat} placeholder='Latitud'
                  onChange={(event) => {
                    setLat(event.target.value);
                  }}
                  type='number'
                  className="form-control"
                />
                <label className="text-white">Longitud:</label>
                <input
                  value={lng} placeholder='Longitud'
                  onChange={(event) => {
                    setLng(event.target.value);
                  }}
                  type='number'
                  className="form-control"
                />
                <br />

                {editar ? (
                  <div>
                    <button type="button" className="btn btn-info" onClick={modificarSucursal}>
                      Actualizar
                    </button>
                    <button type="button" className="btn btn-danger" onClick={cancelar}>
                      Cancelar
                    </button>
                  </div>
                ) : (
                  <button type="button" className="btn btn-info" onClick={registrar}>
                    Añadir
                  </button>
                )}
              </div>
            </div>
          </div>
        </div><br />




      <table className="table">
        <thead className='table-dark'>
          <tr className="table-active">
            <th scope="col">ID</th>
            <th scope="col">Nombre</th>
            <th scope="col">Calle</th>
            <th scope="col">Colonia</th>
            <th scope="col">Número Exterior</th>
            <th scope="col">Teléfono</th>
            <th scope="col">Correo</th>
            <th scope="col">Coordenadas</th>
            <th scope="col">Estatus</th>
            <th scope="col">Modificar</th>
            <th scope="col">Eliminar</th>
          </tr>
        </thead>
        <tbody>
          {
             sucursalLista.map((val,key)=>{
              return <tr className='border border-light border-1' key={val.id_sucursal}>
                  <th className="table-active" scope="row">{val.id_sucursal}</th>
                  <td className="table-active">{val.nombre}</td>
                  <td className="table-active">{val.calle}</td>
                  <td className="table-active">{val.colonia}</td>
                  <td className="table-active">{val.numero_exterior}</td>
                  <td className="table-active">{val.telefono}</td>
                  <td className="table-active">{val.correo}</td>
                  <td className="table-active">{val.lat} <br /> {val.lng} </td>
                  <td className="table-active">{val.estatus}</td>
                  <td className="table-active"><button type="button" className="btn btn-info" onClick={() =>{
                    infoSucursal(val);
                  }}>Modificar</button></td>
                  <td colspan="2" className="table-active"><button type="button" className="btn btn-danger" onClick={() => eliminarSucursal(val.id_sucursal)}>Eliminar</button></td>
                </tr>
             })
          }
              
        </tbody>
      </table>

    </div>
  );
}

export default CrudSucursales;
