import './App.css';
import { useState, useEffect } from "react";
import Axios from "axios";
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link, useNavigate } from 'react-router-dom';


function CrudInventarios() {

  const [id_sucursal, setIdSucursal] = useState(0);
  const [id_producto, setIdProducto] = useState(0);
  const [cantidad, setCantidad] = useState(0);
  const [id_inventario, setIdInventario] = useState(0);
  const [editar, setEditar] = useState(false);

  const [productosLista, setProductosLista] = useState([]);
  const [inventariosLista, setInventariosLista] = useState([]);
  const [sucursalesLista, setSucursalesLista] = useState([]);

  const navigate = useNavigate();

  const handleLogout = () => {
    sessionStorage.clear();
    navigate("/login");
  };

  const userData = JSON.parse(sessionStorage.getItem("userData"));

  if (userData!=null) {
    if (userData.tipo != '2') {
      handleLogout();
    }
  } else {
    handleLogout();
  }

  const registrar = ()=>{

    if (!id_sucursal || !id_producto || !cantidad) {
      alert("Por favor, complete todos los campos antes de dar de alta.");
      return;
    }

    Axios.post('http://localhost:3001/createinv',{
      id_sucursal:id_sucursal,
      id_producto:id_producto,
      cantidad:cantidad
    }).then(()=>{
      getInventarios();
      alert("Se dió de alta correctamente");
      cancelar();
    });
  }

  const getProductos = ()=>{
    Axios.get("http://localhost:3001/productos")
    .then((response)=>{
      setProductosLista(response.data);
    });
  }

  const getInventarios = ()=>{
    Axios.get("http://localhost:3001/inventarios")
    .then((response)=>{
      setInventariosLista(response.data);
    });
  }

  const getSucursales = ()=>{
    Axios.get("http://localhost:3001/sucursales")
    .then((response)=>{
      setSucursalesLista(response.data);
    });
  }

  useEffect(() => {
    getProductos();
  }, []);

  useEffect(() => {
    getInventarios();
  }, []);

  useEffect(() => {
    getSucursales();
  }, []);

  const eliminarInventario = (id_inventario) => {
    Axios.put("http://localhost:3001/eliminarinv/"+id_inventario).then(() => {
      alert("El inventario ha sido eliminado correctamente");
      getInventarios();
    });
  }

  const infoInventarios = (val)=>{
    setEditar(true);
    setIdSucursal(val.id_sucursal);
    setIdProducto(val.id_producto);
    setCantidad(val.cantidad);
    setIdInventario(val.id_inventario);
  }

  const cancelar = ()=>{
    setEditar(false);
    setIdSucursal(0);
    setIdProducto(0);
    setCantidad(0);
  }

    const modificarInventario = ()=>{

      if (!id_sucursal || !id_producto || !cantidad) {
        alert("Por favor, complete todos los campos antes de modificar.");
        return;
      }

      Axios.put('http://localhost:3001/updateinv',{
        id_sucursal:id_sucursal,
        id_producto:id_producto,
        cantidad:cantidad,
        id_inventario:id_inventario
      }).then(()=>{
        cancelar();
        getInventarios();
        alert("El inventario se ha modificado correctamente");
      });
    }

  return (
    <div className="App">

      <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
        <div className="container">
          <form className="d-flex" role="search">
            <Link className="btn btn-info" to={'/admin'}>Regresar</Link>
          </form>
          <a className="navbar-brand mx-auto text-white">CRUD INVENTARIOS</a>
          <form className="d-flex" role="search">
            <button className="btn btn-danger" onClick={handleLogout}>Logout</button>
          </form>
        </div>
      </nav>

        <br /><br />

        <div className="container py-4">
          <div className="row justify-content-center">
            <div className="col-md-8">
            <div className="border border-dark border-1 p-5 d-flex flex-column align-items-center" style={{ backgroundColor: "#888888" }}>
                <label className="label-blancos">Sucursal:</label>
                <select
                  onChange={(event) => {
                    setIdSucursal(event.target.value);
                  }}
                  className="form-control custom-select"
                >
                  {id_sucursal === 0 ? (
                    <>
                      <option disabled selected>Selecciona la sucursal</option>
                      {sucursalesLista.map((val) => (
                        <option key={val.id_sucursal} value={val.id_sucursal}>{val.nombre}</option>
                      ))}
                    </>
                  ) : (
                    <>
                      <option value={id_sucursal}>Sucursal</option>
                      {sucursalesLista.map((val) => (
                        <option key={val.id_sucursal} value={val.id_sucursal}>{val.nombre}</option>
                      ))}
                    </>
                  )}
                </select>
                <label className="label-blancos">Producto:</label>
                <select
                  onChange={(event) => {
                    setIdProducto(event.target.value);
                  }}
                  className="form-control custom-select"
                >
                  {id_producto === 0 ? (
                    <>
                      <option disabled selected>Selecciona el producto</option>
                      {productosLista.map((val) => (
                        <option key={val.id_producto} value={val.id_producto}>{val.nombre}</option>
                      ))}
                    </>
                  ) : (
                    <>
                      <option value={id_producto}>Producto</option>
                      {productosLista.map((val) => (
                        <option key={val.id_producto} value={val.id_producto}>{val.nombre}</option>
                      ))}
                    </>
                  )}
                </select>
                <label className="label-blancos">Cantidad:</label>
                <input
                  value={cantidad} placeholder='Cantidad'
                  onChange={(event) => {
                    setCantidad(event.target.value);
                  }}
                  type="number"
                  className="form-control"
                />
                <br />

                {editar ? (
                  <div>
                    <button type="button" className="btn btn-info" onClick={modificarInventario}>
                      Actualizar
                    </button>
                    <button type="button" className="btn btn-danger" onClick={cancelar}>
                      Cancelar
                    </button>
                  </div>
                ) : (
                  <button type="button" className="btn btn-info" onClick={registrar}>
                    Registrar
                  </button>
                )}
              </div>
            </div>
          </div>
        </div><br />




      <table className="table">
        <thead className='table-dark'>
          <tr className="table-active">
            <th scope="col">ID</th>
            <th scope="col">Sucursal</th>
            <th scope="col">Producto</th>
            <th scope="col">Cantidad</th>
            <th scope="col">Estatus</th>
            <th scope="col">Modificar</th>
            <th scope="col">Eliminar</th>
          </tr>
        </thead>
        <tbody>
          {
             inventariosLista.map((val,key)=>{
              return <tr className='border border-light border-1' key={val.id_inventario}>
                  <th className="table-active" scope="row">{val.id_inventario}</th>
                  <td className="table-active">{val.sucursal}</td>
                  <td className="table-active">{val.producto}</td>
                  <td className="table-active">{val.cantidad}</td>
                  <td className="table-active">{val.estatus}</td>
                  <td className="table-active"><button type="button" className="btn btn-info" onClick={() =>{
                    infoInventarios(val);
                  }}>Modificar</button></td>
                  <td colspan="2" className="table-active"><button type="button" className="btn btn-danger" onClick={() => eliminarInventario(val.id_inventario)}>Eliminar</button></td>
                </tr>
             })
          }
              
        </tbody>
      </table>

    </div>
  );
}

export default CrudInventarios;
