import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './index.css';
import App from './App';
import Login from './login';
import Register from './register';
import Empresa from './empresa';
import Admin from './admin';
import Catalogo from './catalogo';
import About from './about';
import CrudUsuarios from './crud-usuarios';
import CrudProductos from './crud-productos';
import CrudInventarios from './crud-inventarios';
import CrudSucursales from './crud-sucursales';
import Carrito from './carrito';
import Item from './item';
import Compra from './compra';
import Lista from './lista';
import Historial from './historial';
import Facturacion from './facturacion';
import Mapa from './mapa';
import reportWebVitals from './reportWebVitals';
import Pdf from './pdf';

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <Routes>
        <Route path="/" element={<App />} />
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/empresa" element={<Empresa />} />
        <Route path="/about" element={<About />} />
        <Route path="/catalogo" element={<Catalogo />} />
        <Route path="/admin" element={<Admin />} />
        <Route path="/crud-usuarios" element={<CrudUsuarios />} />
        <Route path="/crud-productos" element={<CrudProductos />} />
        <Route path="/crud-inventarios" element={<CrudInventarios/>} />
        <Route path="/crud-sucursales" element={<CrudSucursales/>} />
        <Route path="/carrito" element={<Carrito/>} />
        <Route path="/compra" element={<Compra/>} />
        <Route path="/item" element={<Item/>} />
        <Route path="/lista" element={<Lista/>} />
        <Route path="/historial" element={<Historial/>} />
        <Route path="/facturacion" element={<Facturacion/>} />
        <Route path="/pdf" element={<Pdf/>} />
        <Route path="/mapa" element={<Mapa/>} />
      </Routes>
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
);

reportWebVitals();

