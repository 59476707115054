import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './bootstrap.min.css';
import './sb-admin-2.css';
import 'bootstrap';
import { useState, useEffect } from "react";
import Axios from "axios";
import { Link, useNavigate } from 'react-router-dom';
    
function Item() {

  const [item, setItem] = useState([]);
  const id_inventario = JSON.parse(sessionStorage.getItem("id_inventario"));
  const [cantidad, setCantidad] = useState(0);
  const [inventario, setInventario] = useState(0);
  const [id_usuario, setIdUsuario] = useState(0);
  const precio = (item.length > 0 ? item[0].precio : 0);

  const navigate = useNavigate();

  const handleLogout = () => {
    sessionStorage.clear();
    navigate("/login");
  };

  useEffect(() => {
    const userDataString = sessionStorage.getItem("userData");
    const userData = userDataString ? JSON.parse(userDataString) : null;

    if (userData !== null) {
      if (userData.tipo !== '1') {
        handleLogout();
      }
      setIdUsuario(userData.id_usuario);
    } else {
      handleLogout();
    }
  }, []);

  const getProducto = ()=>{
    Axios.post("http://localhost:3001/item", {
      id_inventario : id_inventario
    }).then((response)=>{
      setItem(response.data);
      setInventario(response.data[0].inventario);
      console.log("Producto obtenidos:", response.data);
    });
  }

  const agregar = () => {
    if (!cantidad || !id_inventario) {
      alert("Por favor, ingrese la cantidad.");
      return;
    }
  
    if (cantidad > inventario) {
      alert("Solo hay " + inventario + " productos disponibles");
      return;
    }
  
    if (cantidad <= 0) {
      alert("Ingrese una cantidad válida");
      return;
    }
  
    Axios.post('http://localhost:3001/agregarcarrito', {
      id_usuario: id_usuario,
      id_inventario: id_inventario,
      cantidad: cantidad,
      subtotal: cantidad * precio
    })
      .then(() => {
        alert("Producto agregado");
        navigate('/carrito');
      })
      .catch((error) => {
        if (error.response && error.response.status === 409) {
          alert("El producto ya está en el carrito");
        } else {
          console.error("Error al agregar el producto al carrito:", error);
        }
      });
  };
  
  
  useEffect(() => {
    getProducto(); 
  }, [id_inventario]);

  useEffect(() => {
    console.log(sessionStorage);
    console.log(id_usuario);
    console.log('cantidad: ' + inventario);
  });

    return (
            
    <div className='App'>

      <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
          <div className="container-fluid">
              <form className="d-flex" role="search">
                <Link className="btn btn-outline-info" to={'/mapa'}>Sucursales</Link>
              </form>
              <form className="d-flex" role="search">
                <Link className="btn btn-outline-info" to={'/catalogo'}>Catalogo</Link>
              </form>
              <form className="d-flex" role="search">
                <Link className="btn btn-outline-info" to={'/lista'}>Lista de deseos</Link>
              </form>
              <form className="d-flex" role="search">
                <Link className="btn btn-outline-info" to={'/carrito'}>Carrito</Link>
              </form>
              <form className="d-flex" role="search">
                <Link className="btn btn-outline-info" to={'/historial'}>Historial</Link>
              </form>
              <form className="d-flex" role="search">
                <button className="btn btn-outline-danger" onClick={handleLogout}>Logout</button>
              </form>        
          </div>
        </nav>

      <div className="p-3">
            <div className="text-center">
            </div>
          </div>
    
        <div className="container-fluid">
      
        <section>
  <div className="container px-4 px-lg-5 my-5">
    {item.map((val) => (
      <div key={val.id} className="row gx-4 gx-lg-5 align-items-center my-4 border border-2 border-dark p-4">
        <div className="col-md-6 order-md-2">
          <img className="img-fluid rounded ml-5 mb-3 mb-md-0 border border-dark" src={`http://localhost:3001/${val.ruta}`} alt={val.nombre} />
        </div>
        <div className="col-md-6 order-md-1">
          <h1 className="display-5 fw-bolder text-black">{val.nombre}</h1>
          <div className="fs-5 mb-4">
            <span className="text-black">${val.precio}</span>
            <p className="lead text-black">Marca: {val.marca}</p>
            <p className="lead text-black">Descripción: {val.descripcion}</p>
            <p className="lead text-black">Cantidad:</p>
            <div className="d-flex align-items-center mb-3">
              <input className="form-control me-3 border-black" type="number" min="1" onChange={(event) => {setCantidad(event.target.value);}} />
              <button type="button" className="btn btn-info" onClick={agregar}>Añadir al carrito</button>
            </div>
          </div>
        </div>
      </div>
    ))}
  </div>
</section>





          
    
        </div>

      <footer>
        <div className="container py-5">

        </div>
      </footer>

    </div>
        
        
        );
}

export default Item;