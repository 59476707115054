import './App.css';
import { useState, useEffect } from "react";
import Axios from "axios";
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link, useNavigate } from 'react-router-dom';


function Historial() {
  
  const [id_usuario, setIdUsuario] = useState(0);
  const [comprasLista, setComprasLista] = useState([]);
  const [id_venta, setIdVenta] = useState(0);


  const navigate = useNavigate();

  const handleLogout = () => {
    sessionStorage.clear();
    navigate("/login");
  };

  useEffect(() => {
    const userDataString = sessionStorage.getItem("userData");
    const userData = userDataString ? JSON.parse(userDataString) : null;

    if (userData !== null) {
      if (userData.tipo !== '1') {
        handleLogout();
      }
      setIdUsuario(userData.id_usuario);
    } else {
      handleLogout();
    }
  }, []);

  const getCompras = ()=>{
    Axios.post("http://localhost:3001/historial", {
      id_usuario : id_usuario
    }).then((response)=>{
      setComprasLista(response.data);
    }).catch((error) => {
      console.error("Error fetching data:", error);
   });;
  }

  const handleIdVenta = (idVenta)=>{
      sessionStorage.setItem("id_venta", JSON.stringify(idVenta));
      navigate('/facturacion');
  }

  useEffect(() => {
    getCompras();
  });

  useEffect(() => {
    console.log(sessionStorage);
  });
  

  return (
    <div className="App">

      <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
          <div className="container-fluid">
              <form className="d-flex" role="search">
                <Link className="btn btn-outline-info" to={'/mapa'}>Sucursales</Link>
              </form>
              <form className="d-flex" role="search">
                <Link className="btn btn-outline-info" to={'/catalogo'}>Catalogo</Link>
              </form>
              <form className="d-flex" role="search">
                <Link className="btn btn-outline-info" to={'/lista'}>Lista de deseos</Link>
              </form>
              <form className="d-flex" role="search">
                <Link className="btn btn-outline-info" to={'/carrito'}>Carrito</Link>
              </form>
              <form className="d-flex" role="search">
                <Link className="btn btn-outline-info" to={'/historial'}>Historial</Link>
              </form>
              <form className="d-flex" role="search">
                <button className="btn btn-outline-danger" onClick={handleLogout}>Logout</button>
              </form>        
          </div>
        </nav>

          <div className="p-5 container-fluid">
            <div className="text-center">
                <h1 className="h1 mb-4">Historial</h1>
            </div>
          </div>

          <div className="container-fluid">
            <div className="container-fluid p-0">
              <div className="table-responsive"> 
                <table className="table table-bordered table-dark">
                  <thead>
                    <tr>
                      <th>Producto</th>
                      <th>Fecha</th>
                      <th>Total</th>
                      <th>Opciones</th>
                    </tr>
                  </thead>
                  {comprasLista.length > 0 ? (
                  <tbody>
                    {
                      comprasLista.map((val) => {
                        return <tr>
                          <td> {val.productos} </td>
                          <td> {val.fecha} </td>
                          <td>$ {val.total} </td>
                          <td>
                            {val.id_factura > 0 ? (
                              <button className="btn btn-outline-info mr-4" type="button" disabled>
                                Ya facturado
                              </button>
                            ) : (
                              <button
                                className="btn btn-outline-info mr-4"
                                type="button"
                                onClick={() => {
                                  handleIdVenta(val.id_venta);
                                }}
                              >
                                Facturar
                              </button>
                            )}
                          </td>

                        </tr>
                      })
                    }
                  </tbody>
                  ) : (
                    <tbody>
                      <tr className="text-center">
                        <td colSpan='6'>No se han realizado compras.</td>
                      </tr>
                    </tbody>
                  )}
                </table>
              </div>
            </div>
          </div>




        <br /><br />

    </div>
  );
}

export default Historial;
