import './App.css';
import { useState, useEffect } from "react";
import Axios from "axios";
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import { Link, useNavigate } from 'react-router-dom';
import md5 from "crypto-js/md5";



function CrudUsuarios() {

  const [correo, setCorreo] = useState("");
  const [nombre, setNombre] = useState("");
  const [psw, setPsw] = useState("");
  const [tipo, setTipo] = useState(0);
  const [editar, setEditar] = useState(false);
  const [id_usuario, setIdUsuario] = useState(0);

  const [usuariosLista, setUsuariosLista] = useState([]);

  const navigate = useNavigate();

  const handleLogout = () => {
    sessionStorage.clear();
    navigate("/login");
  };

  const userData = JSON.parse(sessionStorage.getItem("userData"));

  if (userData!=null) {
    if (userData.tipo != '2') {
      handleLogout();
    }
  } else {
    handleLogout();
  }

  const registrar = ()=>{

    if (!correo || !psw || !tipo || !nombre ) {
      alert("Por favor, complete todos los campos antes de registrar.");
      return;
    }

    Axios.post('http://localhost:3001/create',{
      nombre:nombre,
      correo:correo,
      psw:md5(psw).toString(),
      tipo:tipo
    }).then(()=>{
      getUsuarios();
      alert("Se registro correctamente");
      cancelar();
    });
  }

  const getUsuarios = ()=>{
    Axios.get("http://localhost:3001/usuarios")
    .then((response)=>{
      setUsuariosLista(response.data);
    });
  }

  useEffect(() => {
    getUsuarios();
  }, []);

  const eliminarUsuario = (id_usuario) => {
    Axios.put("http://localhost:3001/eliminar/"+id_usuario).then(() => {
      getUsuarios();
      alert("El usuario ha sido eliminado correctamente");
    });
  }

  const infoUsuario = (val)=>{
    setEditar(true);
    setNombre(val.nombre);
    setCorreo(val.correo);
    setPsw(val.psw);
    setTipo(val.tipo);
    setIdUsuario(val.id_usuario);
  }

  const cancelar = ()=>{
    setEditar(false);
    setNombre("");
    setCorreo("");
    setPsw("");
    setTipo(0);
  }

    const modificarUsuario = ()=>{

      if (!correo || !psw || !tipo || !nombre) {
        alert("Por favor, complete todos los campos antes de modificar.");
        return;
      }

      Axios.put('http://localhost:3001/update',{
        nombre:nombre,
        correo:correo,
        psw:md5(psw).toString(),
        tipo:tipo,
        id_usuario:id_usuario
      }).then(()=>{
        cancelar();
        getUsuarios();
        alert("El usuario se ha modificado correctamente");
      });
    }

  return (
    <div className="App">


      <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
        <div className="container">
          <form className="d-flex" role="search">
            <Link className="btn btn-info" to={'/admin'}>Regresar</Link>
          </form>
          <a className="navbar-brand mx-auto text-white">CRUD USUARIOS</a>
          <form className="d-flex" role="search">
            <button className="btn btn-danger" onClick={handleLogout}>Logout</button>
          </form>
        </div>
      </nav>



        <br /><br /><br />

        <div className="container py-4">
          <div className="row justify-content-center">
            <div className="col-md-8">
              <div className="border border-dark border-1 p-5 d-flex flex-column align-items-center" style={{ backgroundColor: "#888888" }}>
                <label className="label-blancos">Nombre:</label>
                <input
                  value={nombre} placeholder='Nombre'
                  onChange={(event) => {
                    setNombre(event.target.value);
                  }}
                  type="text"
                  className="form-control"
                />
                <label className="label-blancos">Correo:</label>
                <input
                  value={correo} placeholder='Correo'
                  onChange={(event) => {
                    setCorreo(event.target.value);
                  }}
                  type="text"
                  className="form-control"
                />
                <label className="label-blancos">Contraseña:</label>
                <input
                  value={psw} placeholder='Contraseña'
                  onChange={(event) => {
                    setPsw(event.target.value);
                  }}
                  type="password"
                  className="form-control"
                />
                <label className="label-blancos">Tipo:</label>
                <select
                  onChange={(event) => {
                    setTipo(event.target.value);
                  }}
                  className="form-control custom-select"
                >
                  <option disabled > </option> {
                    tipo == 1 ? (
                    <>
                    <option selected value="1">Cliente</option>
                    <option value="2">Administrador</option></>
                    )
                    : tipo == 2 ? (
                      <>
                      <option value="1">Cliente</option>
                      <option selected value="2">Administador</option></>
                    ) 
                    : (
                      <><option disabled selected> Selecciona el tipo </option>
                      <option value="1">Cliente</option>
                      <option value="2">Administrador</option></>
                    )
                  } 
                </select><br />

                {editar ? (
                  <div>
                    <button type="button" className="btn btn-info" onClick={modificarUsuario}>
                      Actualizar
                    </button>
                    <button type="button" className="btn btn-danger" onClick={cancelar}>
                      Cancelar
                    </button>
                  </div>
                ) : (
                  <button type="button" className="btn btn-info" onClick={registrar}>
                    Registrar
                  </button>
                )}
              </div>
            </div>
          </div>
        </div><br />




      <table className="table">
        <thead className='table-dark'>
          <tr className="table-active">
            <th scope="col">ID</th>
            <th scope="col">Nombre</th>
            <th scope="col">Correo</th>
            <th scope="col">Contraseña</th>
            <th scope="col">Tipo</th>
            <th scope="col">Estatus</th>
            <th scope="col">Modificar</th>
            <th scope="col">Eliminar</th>
          </tr>
        </thead>
        <tbody>
          {
             usuariosLista.map((val,key)=>{
              return <tr className='border border-light border-1' key={val.id_usuario}>
                  <th className="table-active" scope="row">{val.id_usuario}</th>
                  <td className="table-active">{val.nombre}</td>
                  <td className="table-active">{val.correo}</td>
                  <td className="table-active">{val.psw}</td>
                  <td className="table-active">{val.tipo}</td>
                  <td className="table-active">{val.estatus}</td>
                  <td className="table-active"><button type="button" className="btn btn-info" onClick={() =>{
                    infoUsuario(val);
                  }}>Modificar</button></td>
                  <td colspan="2" className="table-active"><button type="button" className="btn btn-danger" onClick={() => eliminarUsuario(val.id_usuario)}>Eliminar</button></td>
                </tr>
             })
          }
              
        </tbody>
      </table>

    </div>
  );
}

export default CrudUsuarios;
