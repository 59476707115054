import './App.css';
import { useState, useEffect } from "react";
import Axios from "axios";
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link, useNavigate } from 'react-router-dom';


function Carrito() {
  
  const [cantidad, setCantidad] = useState(0);
  const [id_carrito, setIdCarrito] = useState(0);
  const [id_usuario, setIdUsuario] = useState(0);
  const [productosCarrito, setProductosCarrito] = useState([]);
  const [total, setTotal] = useState(0);


  const navigate = useNavigate();

  const handleLogout = () => {
    sessionStorage.clear();
    navigate("/login");
  };

  useEffect(() => {
    const userDataString = sessionStorage.getItem("userData");
    const userData = userDataString ? JSON.parse(userDataString) : null;

    if (userData !== null) {
      if (userData.tipo !== '1') {
        handleLogout();
      }
      setIdUsuario(userData.id_usuario);
    } else {
      handleLogout();
    }
  }, []);

  const getProductos = ()=>{
    Axios.post("http://localhost:3001/carrito", {
      id_usuario : id_usuario
    }).then((response)=>{
      setProductosCarrito(response.data);
      let subtotalTotal = 0;
      for (const producto of response.data) {
        subtotalTotal += producto.subtotal;
      }
      setTotal(subtotalTotal);
    });
  }

  const modificarCarritoMas = (id_carrito, cantidad, precio, inventario) => {
    if (cantidad < inventario) {
      Axios.put('http://localhost:3001/updatecarritomas', {
        cantidad: cantidad + 1,
        id_carrito: id_carrito,
        precio:precio
      }).then(() => {
        getProductos();
      });
    } else {
      alert('Solo quedan ' + inventario + ' disponibles')
    }
  }
  
  const modificarCarritoMenos = (id_carrito, cantidad, precio) => {

    if (cantidad > 1) {
      Axios.put('http://localhost:3001/updatecarritomenos', {
        cantidad: cantidad - 1,
        id_carrito: id_carrito,
        precio:precio
        
      }).then(() => {
        getProductos();
      });
    } else {
      alert('Cantidad inválida');
    }
  }
  

  const eliminarCarrito = (id_carrito) => {
    const confirmado = window.confirm('Eliminar producto');
  
    if (confirmado) {
      Axios.put('http://localhost:3001/deletecarrito', {
        id_carrito: id_carrito
      }).then(() => {
        getProductos();
      });
    } else {
      console.log('Eliminación cancelada');
    }
  }


  const comprarCarrito = () => {
    const confirmado = window.confirm('Eliminar producto');
  
    if (confirmado) {
      Axios.put('http://localhost:3001/comprar', {
        id_usuario: id_usuario
      }).then(() => {
        getProductos();
      });
    } else {
      console.log('Eliminación cancelada');
    }
  }


  useEffect(() => {
    getProductos();
  }, [id_usuario]);
  

  return (
    <div className="App">

      <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
          <div className="container-fluid">
              <form className="d-flex" role="search">
                <Link className="btn btn-outline-info" to={'/mapa'}>Sucursales</Link>
              </form>
              <form className="d-flex" role="search">
                <Link className="btn btn-outline-info" to={'/catalogo'}>Catalogo</Link>
              </form>
              <form className="d-flex" role="search">
                <Link className="btn btn-outline-info" to={'/lista'}>Lista de deseos</Link>
              </form>
              <form className="d-flex" role="search">
                <Link className="btn btn-outline-info" to={'/carrito'}>Carrito</Link>
              </form>
              <form className="d-flex" role="search">
                <Link className="btn btn-outline-info" to={'/historial'}>Historial</Link>
              </form>
              <form className="d-flex" role="search">
                <button className="btn btn-outline-danger" onClick={handleLogout}>Logout</button>
              </form>        
          </div>
        </nav>

          <div className="p-5 container-fluid">
            <div className="text-center">
                <h1 className="h1 mb-4">Carrito</h1>
            </div>
          </div>

          <div className="container-fluid">
            <div className="container-fluid p-0">
              <div className="table-responsive"> 
                <table className="table table-bordered table-dark">
                  <thead>
                    <tr>
                      <th>Opciones</th>
                      <th>Producto</th>
                      <th>Sucursal</th>
                      <th>Cantidad</th>
                      <th>Precio</th>
                      <th>Subtotal</th>
                    </tr>
                  </thead>
                  {productosCarrito.length > 0 ? (
                  <tbody>
                    {
                      productosCarrito.map((val) => {
                        return <tr>
                          <td>
                            <div>
                              <input className="btn btn-danger mr-4" type="button" value="Eliminar" onClick={() => {eliminarCarrito(val.id_carrito);}} />
                            </div>
                          </td>
                          <td> {val.producto} </td>
                          <td> {val.nombre} </td>

                            <td>
                              <input className="btn btn-info mr-3" type="button" value="-" onClick={() => {modificarCarritoMenos(val.id_carrito, val.cantidad, val.precio);}}/>
                              {val.cantidad}
                              <input className="btn btn-info ml-3" type="button" value="+" onClick={() => {modificarCarritoMas(val.id_carrito, val.cantidad, val.precio, val.inventario);}}/>
                            </td>
                          
                          <td>$ {val.precio} </td>
                          <td>$ {val.subtotal} </td>
                        </tr>
                      })
                    }
                    <tr>
                      <td colSpan="6">Total de todos los productos: $ {total}</td>
                    </tr>
                  </tbody>
                  ) : (
                    <tbody>
                      <tr className="text-center">
                        <td colSpan='6'>No hay productos en el carrito.</td>
                      </tr>
                      <tr>
                        <td colSpan="6">Total de todos los productos: </td>
                      </tr>
                    </tbody>
                  )}
                </table>
              </div>
              <div className="container-fluid">
                <Link className="btn btn-info col-12 col-md-3 mt-2 mb-2"  to={'/compra'} >Comprar</Link>
              </div>
            </div>
          </div>




        <br /><br />

    </div>
  );
}

export default Carrito;