import './App.css';
import { useState, useEffect } from "react";
import Axios from "axios";
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link } from 'react-router-dom';

function Empresa() {

    return(

        <div class="container py-6">

        <div class="card o-hidden border-0 shadow-lg my-5">
            <div class="card-body p-0">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="p-5">
                            <div class="text-center">
                                <h1 className='h1'>Empresa</h1>
                            </div>
                            <div class="text-center">
                                <p className='p-2'>Información empresa</p>
                            </div>
                            
                                <Link class="btn btn-primary btn-user btn-block" to={"/"}>
                                    Regresar
                                </Link>
                            <hr/>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>

    );
}

export default Empresa;