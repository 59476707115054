import { useState, useEffect } from "react";
import Axios from "axios";
import 'bootstrap';
import { Link } from 'react-router-dom';

function App() {
  
  return (

    <div className='App'>

      <div className="p-5 container-fluid">
            <div className="text-center">
                <h1 className="h1 mb-4">INICIO</h1>
            </div>
          </div>

    <div className="container-fluid">
      <div id="carouselExampleDark" className="carousel carousel-dark slide">
        <div className="carousel-indicators">
          <button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
          <button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to="1" aria-label="Slide 2"></button>
          <button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to="2" aria-label="Slide 3"></button>
        </div>
        <div className="carousel-inner">
            <div className="carousel-item active" data-bs-interval="10000">
              <img src={`http://localhost:3001/Fblond.jpeg`} className="d-block mx-auto img-fluid" style={{ width: '500px' }} alt="Slide 1" />
            </div>
            <div className="carousel-item" data-bs-interval="2000">
              <img src={`http://localhost:3001/Fsanta.jpg`} className="d-block mx-auto img-fluid" style={{ width: '500px' }} alt="Slide 2" />
            </div>
            <div className="carousel-item">
              <img src={`http://localhost:3001/Psinger.jpeg`} className="d-block mx-auto img-fluid" style={{ width: '500px' }} alt="Slide 3" />
            </div>
          </div>
        <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleDark" data-bs-slide="prev">
          <span className="carousel-control-prev-icon" aria-hidden="true"></span>
          <span className="visually-hidden">Previous</span>
        </button>
        <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleDark" data-bs-slide="next">
          <span className="carousel-control-next-icon" aria-hidden="true"></span>
          <span className="visually-hidden">Next</span>
        </button>
      </div>
      <div className="container-fluid h-100 py-3">
      <div className="row h-100 justify-content-center align-items-center">
        <div className="col-12 col-md-4 text-center mb-3 mb-md-0">
          <Link to="/login">
            <button className="btn btn-info">Login</button>
          </Link>
        </div>
        <div className="col-12 col-md-4 text-center mb-3 mb-md-0">
          <Link to={"/empresa"}>
            <button className="btn btn-warning">Empresa</button>
          </Link>
        </div>
        <div className="col-12 col-md-4 text-center">
          <Link to={"/about"}>
            <button className="btn btn-success">A cerca de</button>
          </Link>
        </div>
      </div>
    </div>
    </div>


    </div>
  );
}

export default App;




