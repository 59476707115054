import './App.css';
import { useState, useEffect } from "react";
import Axios from "axios";
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link } from 'react-router-dom';
import md5 from "crypto-js/md5";


function Register() {

    const [correo, setCorreo] = useState("");
    const [nombre, setNombre] = useState("");
    const [psw, setPsw] = useState("");
    const [tipo, setTipo] = useState(1);
  
    const registrar = ()=>{
  
      if (!correo || !psw || !tipo || !nombre ) {
        alert("Por favor, complete todos los campos antes de registrar.");
        return;
      }
  
      Axios.post('http://localhost:3001/create',{
        nombre:nombre,
        correo:correo,
        psw: md5(psw).toString(),
        tipo:tipo
      }).then(()=>{
        alert("Se registro correctamente");
      });
    }
  

  
  return (
        
        <div className='App'>

      <div class="container-fluid">

      <div class="card o-hidden border-0 shadow-lg my-5">
          <div class="card-body p-0">
              <div class="row">
                  <div class="col-lg-5 d-none d-lg-block bg-login-image"></div>
                  <div class="col-lg-7">
                      <div class="p-5">
                          <div class="text-center">
                              <h1 class="h4 text-gray-900 mb-4">Crear cuenta</h1>
                          </div>
                          <form class="user">    
                                <div class="form-group">
                                    <input type="text" class="form-control form-control-user" id="exampleFirstName"
                                        placeholder="Nombre" onChange={(event) => {setNombre(event.target.value);}}/>
                                </div>
                                <div class="form-group">
                                    <input type="email" class="form-control form-control-user" id="exampleInputEmail"
                                        placeholder="Correo" onChange={(event) => {setCorreo(event.target.value);}}/>
                                </div>
                                <div class="form-group">
                                    <input type="password" class="form-control form-control-user"
                                        id="exampleInputPassword" placeholder="Contraseña" onChange={(event) => {setPsw(event.target.value);}}/>
                                </div>
                                <Link class="btn btn-info btn-user btn-block" onClick={registrar} to="/login">
                                Registrarse
                                </Link>
                                <Link class="btn btn-info btn-user btn-block">
                                  Cambios
                                </Link>
                                <Link class="btn btn-info btn-user btn-block" to="/">
                                  Cancelar
                                </Link>
                            </form>
                          <hr/>
                          <form className='user'>
                          </form>
                      </div>
                  </div>
              </div>
          </div>
      </div>

      </div>
      </div>
    
    
  );
}

export default Register;
