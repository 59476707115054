import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './bootstrap.min.css';
import './sb-admin-2.css';
import 'bootstrap';
import { GoogleMap, Marker, useLoadScript } from '@react-google-maps/api';
import { useState, useEffect } from "react";
import Axios from "axios";
import { Link, useNavigate } from 'react-router-dom';

const Mapa = () => {

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: "AIzaSyAL4ka0KvM75CicBqpksdAQXpfhg8hFLP4"
  });

  const [ubicaciones, setUbiaciones] = useState([]);

  const navigate = useNavigate();

  const handleLogout = () => {
    sessionStorage.clear();
    navigate("/login");
  };

  const userData = JSON.parse(sessionStorage.getItem("userData"));

  if (userData!=null) {
    if (userData.tipo != '1') {
      handleLogout();
    }
  } else {
    handleLogout();
  }

  const getCoordenadas = ()=>{
    Axios.get("http://localhost:3001/coordenadas", {
    }).then((response)=>{
      setUbiaciones(response.data);
    }).catch((error) => {
      console.error("Error data:", error);
   });;
  }

  useEffect(() => {
    getCoordenadas();
  });

  const mapContainerStyle = {
    width: '100%',
    height: '500px',
  };

  const center = {
    lat: 20.607147252332567,
    lng: -100.40930760298306
  };

  if (!isLoaded) return <div>Loading...</div>;

  if (ubicaciones.length === 0) {
    return <div>No hay sucursales.</div>;
  }

  return (

    
    <div>
        <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
          <div className="container-fluid">
              <form className="d-flex" role="search">
                <Link className="btn btn-outline-info" to={'/mapa'}>Sucursales</Link>
              </form>
              <form className="d-flex" role="search">
                <Link className="btn btn-outline-info" to={'/catalogo'}>Catalogo</Link>
              </form>
              <form className="d-flex" role="search">
                <Link className="btn btn-outline-info" to={'/lista'}>Lista de deseos</Link>
              </form>
              <form className="d-flex" role="search">
                <Link className="btn btn-outline-info" to={'/carrito'}>Carrito</Link>
              </form>
              <form className="d-flex" role="search">
                <Link className="btn btn-outline-info" to={'/historial'}>Historial</Link>
              </form>
              <form className="d-flex" role="search">
                <button className="btn btn-outline-danger" onClick={handleLogout}>Logout</button>
              </form>        
          </div>
        </nav>

        <div className="p-5">
            <div className="text-center">
                <h1 className="h1 mb-4">Sucursales</h1>
            </div>
          </div>
      <GoogleMap
        mapContainerStyle={mapContainerStyle}
        zoom={5}
        center={center}
      >
        {ubicaciones.map((val, index) => (
          <Marker 
            key={index} 
            position={{ lat: val.lat, lng: val.lng }} 
          />
        ))}
      </GoogleMap>
    </div>
  );
};

export default Mapa;