import './App.css';
import { useState, useEffect } from "react";
import Axios from "axios";
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link, useNavigate } from 'react-router-dom';


function Admin() {

  const navigate = useNavigate();

  const handleLogout = () => {
    sessionStorage.clear();
    navigate("/login");
  };

  const userData = JSON.parse(sessionStorage.getItem("userData"));

  if (userData!=null) {
    if (userData.tipo != '2') {
      handleLogout();
    }
  } else {
    handleLogout();
  }
return (
        
<div className='App'>

    <div className="container-fluid">

      <div className="p-5">
        <div className="text-center">
            <h1 className="h1 mb-4">Panel administrador</h1><br />
        </div>
        <form className="user"><hr />
            <Link className="btn btn-info btn-user btn-block" to={'/crud-usuarios'}>
            Usuarios
            </Link><hr />
            <Link className="btn btn-info btn-user btn-block" to={'/crud-productos'}>
            Productos
            </Link><hr />
            <Link className="btn btn-info btn-user btn-block" to={'/crud-sucursales'} >
            Sucursales
            </Link><hr />
            <Link className="btn btn-info btn-user btn-block" to={'/crud-inventarios'} >
            Inventarios
            </Link><hr />
            <button className="btn btn-danger btn-user btn-block" onClick={handleLogout}>
            Cerrar sesión
            </button>
        </form>
        <hr/>
    </div>

    </div>
</div>
    
    
  );
}

export default Admin;
