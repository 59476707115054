import './App.css';
import { useState, useEffect } from "react";
import Axios from "axios";
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link, useNavigate } from 'react-router-dom';


function Lista() {
  
  const [id_usuario, setIdUsuario] = useState(0);
  const [productosLista, setProductosLista] = useState([]);
  const [total, setTotal] = useState(0);


  const navigate = useNavigate();

  const handleLogout = () => {
    sessionStorage.clear();
    navigate("/login");
  };

  useEffect(() => {
    const userDataString = sessionStorage.getItem("userData");
    const userData = userDataString ? JSON.parse(userDataString) : null;

    if (userData !== null) {
      if (userData.tipo !== '1') {
        handleLogout();
      }
      setIdUsuario(userData.id_usuario);
    } else {
      handleLogout();
    }
  }, []);

  const getProductos = ()=>{
    Axios.post("http://localhost:3001/lista", {
      id_usuario : id_usuario
    }).then((response)=>{
      setProductosLista(response.data);
      let subtotalTotal = 0;
      for (const producto of response.data) {
        subtotalTotal += producto.subtotal;
      }
      setTotal(subtotalTotal);
    });
  }

  const agregar = (id_inventario, cantidad, precio, id_lista) => {
  
    Axios.post('http://localhost:3001/agregarcarritolista',{
      id_usuario:id_usuario,
      id_lista:id_lista,
      id_inventario:id_inventario,
      cantidad:cantidad,
      subtotal: (cantidad*precio)
    }).then(()=>{
      alert("Producto agregado");
      navigate('/carrito');
    });
  }

  const modificarListaMas = (id_lista, cantidad, precio, inventario) => {
    if (cantidad < inventario) {
      Axios.put('http://localhost:3001/updatelistamas', {
        cantidad: cantidad + 1,
        id_lista: id_lista,
        precio:precio
      }).then(() => {
        getProductos();
      });
    } else {
      alert('Solo quedan ' + inventario + ' disponibles')
    }
  }
  
  const modificarListaMenos = (id_lista, cantidad, precio) => {

    if (cantidad > 1) {
      Axios.put('http://localhost:3001/updatelistamenos', {
        cantidad: cantidad - 1,
        id_lista: id_lista,
        precio:precio
        
      }).then(() => {
        getProductos();
      });
    } else {
      alert('Cantidad inválida');
    }
  }
  

  const eliminarLista = (id_lista) => {
    const confirmado = window.confirm('Eliminar producto');
  
    if (confirmado) {
      Axios.put('http://localhost:3001/deletelista', {
        id_lista: id_lista
      }).then(() => {
        getProductos();
      });
    } else {
      console.log('Eliminación cancelada');
    }
  }

  useEffect(() => {
    getProductos();
  }, [id_usuario]);
  

  return (
    <div className="App">

      <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
          <div className="container-fluid">
              <form className="d-flex" role="search">
                <Link className="btn btn-outline-info" to={'/mapa'}>Sucursales</Link>
              </form>
              <form className="d-flex" role="search">
                <Link className="btn btn-outline-info" to={'/catalogo'}>Catalogo</Link>
              </form>
              <form className="d-flex" role="search">
                <Link className="btn btn-outline-info" to={'/lista'}>Lista de deseos</Link>
              </form>
              <form className="d-flex" role="search">
                <Link className="btn btn-outline-info" to={'/carrito'}>Carrito</Link>
              </form>
              <form className="d-flex" role="search">
                <Link className="btn btn-outline-info" to={'/historial'}>Historial</Link>
              </form>
              <form className="d-flex" role="search">
                <button className="btn btn-outline-danger" onClick={handleLogout}>Logout</button>
              </form>        
          </div>
        </nav>

          <div className="p-5 container-fluid">
            <div className="text-center">
                <h1 className="h1 mb-4">Lista de deseos</h1>
            </div>
          </div>

          <div className="container-fluid">
            <div className="container-fluid p-0">
              <div className="table-responsive"> 
                <table className="table table-bordered table-dark">
                  <thead>
                    <tr>
                      <th>Opciones</th>
                      <th>Producto</th>
                      <th>Sucursal</th>
                      <th>Cantidad</th>
                      <th>Precio</th>
                      <th>Subtotal</th>
                    </tr>
                  </thead>
                  {productosLista.length > 0 ? (
                  <tbody>
                    {
                      productosLista.map((val) => {
                        return <tr>
                          <td>
                            <div>
                              <input className="btn btn-danger mr-4" type="button" value="Eliminar" onClick={() => {eliminarLista(val.id_lista);}} />
                              <input className="btn btn-success mr-4" type="button" value="Agregar a carrito" onClick={() => {agregar(val.id_inventario, val.cantidad, val.precio, val.id_lista);}} />
                            </div>
                          </td>
                          <td> {val.producto} </td>
                          <td> {val.nombre} </td>

                            <td>
                              <input className="btn btn-info mr-3" type="button" value="-" onClick={() => {modificarListaMenos(val.id_lista, val.cantidad, val.precio);}}/>
                              {val.cantidad}
                              <input className="btn btn-info ml-3" type="button" value="+" onClick={() => {modificarListaMas(val.id_lista, val.cantidad, val.precio, val.inventario);}}/>
                            </td>
                          
                          <td>$ {val.precio} </td>
                          <td>$ {val.subtotal} </td>
                        </tr>
                      })
                    }
                    <tr>
                      <td colSpan="6">Total de todos los productos: $ {total}</td>
                    </tr>
                  </tbody>
                  ) : (
                    <tbody>
                      <tr className="text-center">
                        <td colSpan='6'>No hay productos en la lista.</td>
                      </tr>
                      <tr>
                        <td colSpan="6">Total de todos los productos: </td>
                      </tr>
                    </tbody>
                  )}
                </table>
              </div>
            </div>
          </div>




        <br /><br />

    </div>
  );
}

export default Lista;
